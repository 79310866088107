.messages-page-tabs-container {
  display: flex;
  justify-content: center;
}
.messages-page-tabs-content {
  min-width: 70%;
  margin: 1em 0 0 0;
}
.messages-page-tabs-area {
  cursor: pointer;
  border-bottom: solid 2px lightgray;
}
.messages-page-tabs-area:hover {
  cursor: pointer;
  color: #5755d9;
}
.messages-page-tabs-area.active {
  border-bottom: solid 2px #5755d9;
  color: #5755d9;
}